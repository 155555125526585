@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* .App {
  font-family: Poppins;
} */
a {
  text-decoration: none !important;
}
.connect_btn {
  text-decoration: none;
  cursor: pointer;
}

.css-1qzevvg {
  background: #ffffff00;
  padding: 20px 40px;
  border-radius: 50px;
  box-shadow: 5px 10px 18px #ffffff00;
}

.footer__img {
  width: 50%;
}

.css-1qzevvg img {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .artGallery {
    font-size: 30px;
  }
  .footer__img {
    width: 100%;
  }
}
